<template>
  <div id="content" class="content-wrapper">
    <b-overlay :show="show" :variant="variant" rounded="sm">
      <router-view> </router-view>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "Content",
  data() {
    return {
      show: false,
      variant: "dark",
    };
  },
  methods: {},
  mounted() {
    //ค่อยแก้ใหม่ ใช้แบบนี้ไม่ได้
    setInterval(() => {
      let show_get = localStorage.getItem("show");
      this.show = show_get;
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  margin-top: 70px;
}
</style>
