import VueI18n from "vue-i18n";
import Vue from "vue";
import en from "./en/index";
import th from "./th/index";

Vue.use(VueI18n);

const messages = {
  en: en,
  th: th,
};
export default new VueI18n({
  locale: localStorage.getItem("lang"),
  fallbackLocale: 'en', // set fallback locale
  messages, //set locale messages
});
