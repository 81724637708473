export default {
  message: {
    HOME: "HOME",
    ABOUT: "ABOUT US",
    SHOWCASE: "SHOWCASE",
    SERVICE: "SERVICE",
    ALIBABA: "ALIBABA",
    CONTACT: "CONTACT US",
    ORDER: "ORDER & SHIPPING",
    ORDERFOTER: "OUR SERVICES",
    TH: "TH",
    EN: "EN",
    FOOTTER: "Copyright © 2009 Bella Jewelry.com All rights reserved.",
    /* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
    // Home
    content1: "COMPANY",
    content2: "BELLA JEWELRY",
    content3:
      "A fine Jewelry manufacture for gold and silver for OEM Designers and wholesalers around the world.",
    /* ------------------ */
    content4: "BRIEF HISTORY",
    content5:
      "With over 70 years of experience in manufacturing fine gold jewelry for top-end locals and over 40 years of export experience has transformed us to a leading In-House Full-service OEM Factory for lading jewelry designers and wholesalers for Medium to Hi-End market around the word with exceptional craftsmanship quality.",
    content6: "READ MORE",
    /* ------------------ */
    content7: "DESIGNER WELCOME",
    content8:
      "Our Medium Size Factory is advantageous and consists of more than 270 skillful artisans and Full house service for every manufacturing process. In particular, Stone Cutting which is a rare process in general jewelry factories. Therefore, Bella Jewelry has been selected by top-class designers because we are approachable and flexible combined with professionals whether it be quality, unique design, workflow and price.",
    content9: "VIEW MORE",
    /* -------Card_About.vue----------- */
    content10: "COMMITMENT",
    /* --- */
    content11: "1",
    content12: "QUALITY",
    content13:
      "Quality has always been our top priority. We have many loyal customers who have enjoy our products and services quality so much that they keep ordering from our factory for over 10 or 20 years.",
    /* --- */
    content14: "2",
    content15: "EXCLUSIVITY",
    content16:
      "Having an In-House Full Service from sketching to stone cutting can guarantee exclusivity of your design and able to customize to meet special requirement of your product.",
    /* --- */
    content17: "3",
    content18: "FLEXIBILITY",
    content19:
      "We have prioritized flexibility in communication, design and manufacturing process as well as to be professional manufacture and high quality to meet the needs of our customers as much as possible.",
    /* --- */
    content20: "4",
    content21: "TRUSTWORTHY",
    content22:
      "We have prioritized honesty and faithfulness to ensure all customers that they will receive the highest quality products at a reasonable price and are the most trustworthy.",
    /* ------------------ */
    content23: "SHOWCASE",
    content24: "VIEW MORE",
    //compontent gettouch
    /* -------gettouch.vue----------- */
    content25: "GET IN TOUCH WITH US",
    content26: "Please contact for more information",
    content27: "GET IN TOUCH",
    /* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
    // ABOUT
    content28: "ABOUT US",
    content29:
      "A fine Jewelry manufacture for gold and silver for OEM Designers and wholesalers around the world.",
    content30: "HISTORY",
    content31: "COMMITMENT",
    content32: "WHY BELLA",
    content33: "OUR CLIENTS AND PARTNERS",
    content36:
      "Founded in early 1940 over 70 years ago as a gold smiths and has grown to an export factory Bella Jewelry (Factory), Ltd in 1979 where we have been focused on manufacturing and exporting 8K-18K Gold and Silver Fine Jewelry for customers around the world for over 40 years.",
    content101:
      "Our factory consists of diverse ages in the workplace and currently employ over 250 talented artisans which many of them have been working with our factory over 30 or 40 years.",
    content102:
      "Presently, Bella Jewellery (Factory), Ltd is an In-House Full-Service OEM factory that exports to North America 70% and Europe 30%. Our customers consist of famous wholesalers and designers all over the world.",
    /* --------whybella.vue---------- */
    content34: "QUALITY REPUTATION",
    content35:
      "With over 70 years of experience in manufacturing fine gold jewelry for top-end locals and nearly 30 years subcontracting for leading jewelry brands and factories around the world, we are well-known among the local Thai jewelry industry in providing exceptional craftsmanship quality.",
    content103:
      "Our commitment to provide highest quality craftsmanship is highlighted by our strict QC practice. Specially trained QC staff check every individual piece of jewelry at each manufacture step, from casting to packing for any imperfection of material or craftsmanship to ensure that every piece of jewelry we ship to you is top quality without any visible and measurable defect. This strict QC practice rewards us with a low rejection rate from customers.",
    content37: "DESIGNER WELCOME",
    content38:
      "Our Medium Size Factory is advantageous and consists of more than 270 skillful artisans and Full house service for every manufacturing process. In particular, Stone Cutting which is a rare process in general jewelry factories. Therefore, Bella Jewelry has been selected by top-class designers because we are approachable and flexible combined with professionals whether it be quality, unique design, workflow and price.",
    content39: "FULL RANGE OF SERVICES WITH NO MINIMUM ORDER REQUIREMENT",
    content40:
      "All manufacturing processes are in-house including a stone cutting department to handle special cutting requirements. This facility enables us to fulfil orders of any size and from value-oriented markets to one-of-a-kind work of art.",
    /* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
    //SHOWCACS
    content41: "SHOWCASE",
    content42:
      "Bella Jewelry (Factory), Ltd is proficient of all types of Fine Jewelry from mass to one of a kind. We offer Full In-House service with our own custom stone cutting service with no minimum order and are capable of creating jewelries to meet the needs of your brand no matter whether you are a Designer or Wholesalers. ",
    content43: "Request for our lastest collection",
    // content43: "Visit our collections here",
    /* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
    //SERVICE
    content44: "WHAT WE DO",
    content45: "OUR SERVICE",
    // content45: "BELLA JEWELRY ’S SERVICE",
    content46:
      "We manufacture Jewellery for Jewellery brands and wholesalers around the world and we offer a complete range and one-stop service for fine jewellery designers, with flexible minimum order requirement",
    /* --------Card_Silder.vue---------- */
    content47: "PRODUCTION",
    content48: "METAL",
    content49:
      "925 Sterling Silver 8-18k Gold in Yellow, Pink and White Nickel free alloy for European Standard",
    content50: "COST AND MINIMUM ORDER",
    content51:
      "No minimum order. The Cost for a model building is between US$30 to US$200 depending on size and difficulty of the design",
    content52: "TERMS OF PAYMENT",
    content53: "30% deposit before production, 70% balance before shipping",
    /* ---------Services.vue---------- */
    content54: "SERVICES",
    content55:
      "We have in-house full service for jewelry manufacture comprehensively. With our 70 years’ experience 270 artisans combine with modern technology into the workflow. You are able to select our specific or entire workflow as follows",
    content56: "DESIGN EXECUTIONS INCLUDING SKETCHING / CAD CAM SERVICE",
    content57: "MOLD MAKING / MOLD CASTING / DIRECT-PRINT WAX",
    // content57: "MOLD MAKING / TYPO MOLD CASTING",
    content58: "STONE CUTTING",
    content59: "MOUNTING",
    content60: "POLISHING / PLATING",
    content61: "QUALITY CONTROL",
    /* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
    //ORDER
    content62: "ORDER & SHIPPING",
    content63: "Comprehensive Express Shipping Support Area for Customers",
    content64: "COST AND MINIMUM ORDER",
    content65:
      "For your utmost convenience, we have concluded how to order into 3 ways as follows",
    content66: "FREQUENTLY ASKED QUESTION",
    /* --- */
    content67: "1",
    content68: "Choose from our collections",
    content69: "VISIT BELLA’S JEWELRY ON ALIBABA",
    content699: "OR",
    content6999: "REQUEST FOR OUR LASTEST COLLECTION",

    content70: "2",
    content71:
      "Choose from our collections but personalize some of the jewelry",
    content72: "VISIT BELLA’S JEWELRY ON ALIBABA",
    content722: "OR",
    content7222: "REQUEST FOR OUR LASTEST COLLECTION",

    content73: "3",
    content74: "Custom Design is available",
    content75: "SEND US YOUR DESIGN",
    /* --- */
    content76: "Can i customise products?",
    content77:
      "Yes.You can either choose from our designs but personalize on stone selection, logo, plating, alloy color, gold karatage or send your custom made design.Let me know the specific customization requirements and I will get back to you as soon as possible.",

    content78: "Terms of payment",
    content79: "30% deposit before production, 70% balance before shipping",

    content80: "What is your Minimum Order Quantity and pricing?",
    content81:
      "No minimum order. The Cost for a model building is usually between US$30 to US$200 depending on size and difficulty of the design.",

    content82: "How long will it take to ship to my country?",
    content83:
      "It takes 4-5 weeks for production after the the deposit is received. Delivery. By insured FedEx, UPS or DHL",
    /* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
    //CONTACT
    content84: "CONTACT US",
    content85: "LET’S WORK TOGETHER",
    content86: "Locations",
    content87:
      "214,216 Charansanitwong 42 road, Charansanitwong, Bangplat, Bangyikan, Bangkok, Thailand 10700",
    content88: "Email",
    content89: "viruch@mondial.co.th",
    content90: "Phone",
    content91: "+662-423-0150 Ext 58",
    /* --- */
    content92: "Full Name",
    content93: "Your name",
    content94: "Email Address",
    content95: "Your email",
    content96: "Phone Number",
    content97: "Your phone number",
    content98: "Message",
    content99: "Your message",
    content100: "SEND MESSAGE",
    content110: "FAIR EXHIBITIONS",
    content111: "MOLD MAKING",
    content112: "MOLD CASTING",
    content113: "DIRECT-PRINT WAX",
  },
};
