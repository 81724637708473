<template>
  <div id="app">
    <Header />
    <Content />
    <Footer title="Copyright © 2009 Bella Jewelry.com All rights reserved." />
  </div>
</template>

<script>
import Footer from "@/components/layout/Footer.vue";
import Header from "@/components/layout/Header.vue";
import Content from "@/components/layout/Content.vue";

export default {
  name: "app",
  components: {
    Footer,
    Header,
    Content,
  },
  data() {
    return {
      language: null,
    };
  },
  methods: {

  },
};
</script>

<style scoped>
.content {
  color: violet;
}
</style>
