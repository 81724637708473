<template>
  <div>
    <nav class="row navbar navbar-expand-lg bg-navber fixed-top ">
      <!-- <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top"> -->
      <div class="container-fluid position-relative">
        <div class="navbar-brand auto-left-five" href="/">
          <img src="@/assets/icon/logo.png" style="height: 45px;width: 50px;" />
        </div>
        <div class="auto-right-three">
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation "
            @click="clikfortrue()"
          >
            <div v-if="isLogged">
              <img src="@/assets/icon/Icon_Menu.png" class="icon-manu" />
            </div>

            <div v-else>
              <img src="@/assets/icon/Icon_Close.png" class="icon-manu" />
            </div>
          </button>
        </div>
        <div
          class=" collapse navbar-collapse flex-end auto-right-ten"
          id="navbarSupportedContent"
        >
          <ul class=" navbar-nav ml-auto mb-2 mb-lg-0 ">
            <li
              class="nav-item "
              @click="action_button((activeBtn = 'btn1'))"
              :class="{ activenavbar: activeBtn == 'btn1' }"
            >
              <b-nav-item class="text-header-nav" href="/">{{
                $t("message.HOME")
              }}</b-nav-item>
            </li>

            <li
              class="nav-item "
              @click="action_button((activeBtn = 'btn2'))"
              :class="{ activenavbar: this.activeBtn == 'btn2' }"
            >
              <b-nav-item class=" text-header-nav" href="/about">{{
                $t("message.ABOUT")
              }}</b-nav-item>
            </li>

            <li
              class="nav-item"
              @click="action_button((activeBtn = 'btn3'))"
              :class="{ activenavbar: activeBtn == 'btn3' }"
            >
              <b-nav-item class=" text-header-nav" href="/showcase">{{
                $t("message.SHOWCASE")
              }}</b-nav-item>
            </li>
            <li
              class="nav-item"
              @click="action_button((activeBtn = 'btn4'))"
              :class="{ activenavbar: activeBtn === 'btn4' }"
            >
              <b-nav-item class=" text-header-nav" href="/service">{{
                $t("message.SERVICE")
              }}</b-nav-item>
            </li>
            <li
              class="nav-item"
              @click="action_button((activeBtn = 'btn5'))"
              :class="{ activenavbar: activeBtn === 'btn5' }"
            >
              <b-nav-item class=" text-header-nav" href="/order">{{
                $t("message.ORDER")
              }}</b-nav-item>
            </li>
            <li
              class="nav-item"
              @click="action_button((activeBtn = 'btn6'))"
              :class="{ activenavbar: activeBtn === 'btn6' }"
            >
              <b-nav-item class=" text-header-nav" href="/contact">{{
                $t("message.CONTACT")
              }}</b-nav-item>
            </li>
            <li
              class="nav-item"
              @click="activeBtn = 'btn7'"
              :class="{ activenavbar: activeBtn === 'btn7' }"
            >
              <b-nav-item
                class=" text-header-nav"
                href="https://th1337639403eyem.trustpass.alibaba.com/"
                >{{ $t("message.ALIBABA") }}</b-nav-item
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      isLogged: true,
      activeBtn: null,
      activeCrl: "clr1",
      lang: "en",
      isLogged11: false,
    };
  },
  methods: {
    set() {
      let get_Btn = JSON.parse(localStorage.getItem("activeBtn_set"));
      if (get_Btn === null) {
        var button_get = "btn1";
        this.activeBtn = button_get;
        return;
      }
      this.activeBtn = get_Btn;
      localStorage.clear();
      localStorage.setItem("activeBtn_set", JSON.stringify(this.activeBtn));
      return;
    },
    switchLocaleEn() {
      this.$i18n.locale = "en";
      this.lang = "en";
      // localStorage.setItem("lang", "en");
    },
    switchLocaleTh() {
      this.$i18n.locale = "th";
      this.lang = "th";
      // localStorage.setItem("lang", "th");
    },
    clikfortrue() {
      if (this.isLogged === false) {
        this.isLogged = true;
        localStorage.clear();
      } else {
        this.isLogged = false;
        let show = true;
        localStorage.setItem("show", JSON.stringify(show));
      }
    },
    action_button(activeBtn) {
      localStorage.setItem("activeBtn_set", JSON.stringify(activeBtn));
    },
  },
  mounted() {
    this.set();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/main";
@import "@/assets/scss/header";
</style>
